import { combineEpics, ofType } from 'redux-observable';
import { getJSONEpic } from '../../shared/requestEpic';
import {
  CREATE_RESIDENTIAL_CLIENT,
  CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  DELETE_RESIDENTIAL_CLIENT,
  GET_CLIENT_INFO,
  GET_RESIDENTIAL_CLIENTS,
  UPDATE_CLIENT_INFO,
  UPDATE_RESIDENTIAL_CLIENT,
  UPDATE_RESIDENTIAL_CLIENT_SUCCESS
} from './actionTypes';
import { ProfileInfoUrl } from '../../shared/urls';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  deleteResidentialClientSuccess,
  getClientInfoSuccess,
  getResidentialClientsSuccess,
  updateClientInfoSuccess
} from './actions';
import { RequestMethod } from '../../shared/const/requestMethod';
import requestEpic from '../../shared/requestEpic';
import { requireValidToken } from '../../shared/refreshToken';
import { ajax } from 'rxjs/ajax';
import qs from 'qs';
import { of } from 'rxjs';
import { requestFailure } from '../../redux/handleError';
import { closeDrawer } from './actions';
const BASE_URL = process.env.REACT_APP_BASE_URL || '';

const createResidentialClientEpic = requestEpic(
  CREATE_RESIDENTIAL_CLIENT,
  RequestMethod.POST,
  ProfileInfoUrl.CREATE_RESIDENTIAL_CLIENT_URL,
  mergeMap(res => {
    return of(
      { type: CREATE_RESIDENTIAL_CLIENT_SUCCESS, res: res.response.data },
      closeDrawer()
    );
  })
);

const updateResidentialClientEpic = requestEpic(
  UPDATE_RESIDENTIAL_CLIENT,
  RequestMethod.POST,
  ProfileInfoUrl.UPDATE_RESIDENTIAL_CLIENT_URL,
  mergeMap(res => {
    return of(
      { type: CREATE_RESIDENTIAL_CLIENT_SUCCESS, res: res.response.data },
      closeDrawer()
    );
  })
);

const getResidentialClientsEpic = getJSONEpic(
  GET_RESIDENTIAL_CLIENTS,
  ProfileInfoUrl.GET_RESIDENTIAL_CLIENTS_URL,
  map(res => getResidentialClientsSuccess(res.data))
);

const getClientInfoEpic = getJSONEpic(
  GET_CLIENT_INFO,
  ProfileInfoUrl.GET_CLIENT_INFO_URL,
  map(res => getClientInfoSuccess(res.data))
);

const updateClientInfoEpic = requestEpic(
  UPDATE_CLIENT_INFO,
  RequestMethod.POST,
  ProfileInfoUrl.UPDATE_CLIENT_INFO_URL,
  map(res => updateClientInfoSuccess(res.data))
);

const deleteResidentialClientEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_RESIDENTIAL_CLIENT),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      return requireValidToken(
        action$,
        state$,
        state.account.detail.tokens,
        newToken => {
          return ajax({
            method: 'POST',
            url: `${BASE_URL}${ProfileInfoUrl.DELETE_CLIENT_INFO_URL}`,
            body: qs.stringify({
              data: JSON.stringify({
                ...action.params
              })
            }),
            headers: {
              Authorization: `${newToken}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).pipe(
            mergeMap(() =>
              of(deleteResidentialClientSuccess(action.params), closeDrawer())
            ),
            catchError(error => {
              return of(
                requestFailure(error.xhr.response.errors, error.status)
              );
            })
          );
        }
      );
    })
  );

export default combineEpics(
  createResidentialClientEpic,
  updateResidentialClientEpic,
  getResidentialClientsEpic,
  getClientInfoEpic,
  updateClientInfoEpic,
  deleteResidentialClientEpic
);
