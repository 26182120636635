// @flow
import {
  LOG_OUT,
  LOGIN,
  LOGIN_SUCCESS,
  LOG_OUT_FAILURE,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  LOG_OUT_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  RESET_PASSWORD, RESET_PASSWORD_SUCCESS,
  VERIFY_EMAIL, VERIFY_EMAIL_SUCCESS,
} from './actionTypes';
import type { LoginRequestModel, Token, UserResponseModel } from './model';
import type {
  ATLogoutSuccess,
  ATRefreshToken,
  ATRefreshTokenSuccess,
  LoginAction,
  LoginActionFailure,
  LoginActionSuccess,
  LogoutAction,
  SignUp,
  SignUpFailure,
  SignUpSuccess,
  UpdateProfile,
  ConfirmMail,
  ConfirmMailSuccess,
} from './actionTypes';

export const login = (userData: LoginRequestModel): LoginAction => ({
  type: LOGIN,
  payload: userData,
});

export const loginSuccess = (
  response: UserResponseModel
): LoginActionSuccess => ({
  type: LOGIN_SUCCESS,
  response,
});

export const loginFailure = (error: any): LoginActionFailure => ({
  type: LOG_OUT_FAILURE,
  error,
});

export const logout = (): LogoutAction => ({
  type: LOG_OUT,
});

export const logoutSuccess = (res: boolean): ATLogoutSuccess => ({
  type: LOG_OUT_SUCCESS,
  res,
});

export const signUp = (params: LoginRequestModel): SignUp => ({
  type: SIGN_UP,
  params,
});

export const signUpSuccess = (response: UserResponseModel): SignUpSuccess => ({
  type: SIGN_UP_SUCCESS,
  response,
});

export const signUpFailure = (error: any): SignUpFailure => ({
  type: SIGN_UP_FAILURE,
  error,
});

export const updateProfile = (params: UserResponseModel): UpdateProfile => ({
  type: UPDATE_PROFILE,
  params,
});

export const updateProfileSuccess = (res: any) => ({
  type: UPDATE_PROFILE_SUCCESS,
  res,
});

export const confirmEmail = (params: any): ConfirmMail => ({
  type: CONFIRM_EMAIL,
  params,
});

export const resetPassword = (params: any) => ({
  type: RESET_PASSWORD,
  params,
});

export const resetPasswordSuccess = (response: any) => ({
  type: RESET_PASSWORD_SUCCESS,
  response,
});

export const confirmEmailSuccess = (res: any): ConfirmMailSuccess => ({
  type: CONFIRM_EMAIL_SUCCESS,
  res,
});

export const refreshToken = (): ATRefreshToken => ({
  type: REFRESH_TOKEN,
});

export const refreshTokenSuccess = (res: Token): ATRefreshTokenSuccess => ({
  type: REFRESH_TOKEN_SUCCESS,
  res,
});

export const verifyEmail = (params: any) => ({
  type: VERIFY_EMAIL,
  params,
});

export const verifyEmailSuccess = (response: any) => ({
  type: VERIFY_EMAIL_SUCCESS,
  response,
});