// @flow
import type { ExportGuestBookParams } from './model';

export const EXPORT_GUEST_BOOK = 'export_guest_book';
export const EXPORT_GUEST_BOOK_SUCCESS = 'export_guest_book_Success';

export type ATExportGuestBook = {
  type: string,
  params: ExportGuestBookParams,
};

export type ATExportGuestBookSuccess = {
  type: string,
  res: any,
};
