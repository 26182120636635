import { makeRequest } from '../redux/apiCall';
import {
  URL_PROPERTIES_GET_ALL,
  URL_PROPERTIES_GET_ONE,
  URL_ACCOMMODATIONS_ADD_NEW,
  URL_UPDATE_PROPERTY,
} from '../shared/urls';
import { uploadFile } from '../custom-hooks/apiHook';

export const GET_PROPERTIES = 'get_properties';
export const GET_PROPERTIES_SUCCESS = 'get_properties_success';
export const GET_PROPERTIES_FAILURE = 'get_properties_failure';

export const GET_PROPERTY_DETAIL = 'get_property';
export const GET_PROPERTY_DETAIL_SUCCESS = 'get_property_success';
export const GET_PROPERTY_DETAIL_FAILURE = 'get_property_failure';

export const ADD_PROPERTY = 'add_property';
export const ADD_PROPERTY_SUCCESS = 'add_property_success';
export const ADD_PROPERTY_FAILURE = 'add_property_failure';

export const UPDATE_PROPERTY = 'update_property';
export const UPDATE_PROPERTY_SUCCESS = 'update_property_success';
export const UPDATE_PROPERTY_FAILURE = 'update_property_failure';

export const getProperties = () =>
  makeRequest(
    GET_PROPERTIES,
    'get',
    URL_PROPERTIES_GET_ALL,
    null,
    getProperties,
  );

export const getPropertyDetail = params =>
  makeRequest(
    GET_PROPERTY_DETAIL,
    'post',
    URL_PROPERTIES_GET_ONE,
    params,
    getPropertyDetail,
  );

export const addProperty = params =>
  makeRequest(
    ADD_PROPERTY,
    'post',
    URL_ACCOMMODATIONS_ADD_NEW,
    params,
    addProperty,
  );

export const updateProperty = params =>
  makeRequest(
    UPDATE_PROPERTY,
    'post',
    URL_UPDATE_PROPERTY,
    params,
    updateProperty,
    true,
  );

export const addPropertyWithPhoto = (params, file) => async dispatch => {
  let data = new FormData();
  data.append('coverImage', file);
  data.append('data', JSON.stringify(params));
  dispatch({ type: ADD_PROPERTY });
  try {
    await uploadFile(URL_ACCOMMODATIONS_ADD_NEW, data);
    dispatch({ type: ADD_PROPERTY_SUCCESS, res: params });
  } catch (e) {
    dispatch({ type: ADD_PROPERTY_FAILURE, error: e });
  }
};

export const updatePropertyWithPhoto = (params, file) => async dispatch => {
  let data = new FormData();
  data.append('coverImage', file);
  data.append('data', JSON.stringify(params));
  dispatch({ type: UPDATE_PROPERTY });
  try {
    await uploadFile(URL_UPDATE_PROPERTY, data);
    dispatch({ type: UPDATE_PROPERTY_SUCCESS, res: params });
  } catch (e) {
    dispatch({ type: UPDATE_PROPERTY_FAILURE, error: e });
  }
};
