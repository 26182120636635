// @flow
import { ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import qs from 'qs';
import { of } from 'rxjs';
import { requestFailure } from '../redux/handleError';
import type { RequestMethodType } from './const/requestMethod';
import { isEmpty } from 'lodash';
import { requireValidToken } from './refreshToken';

const BASE_URL = process.env.REACT_APP_BASE_URL || '';

export type Url = string;

export default (
  actionType: string,
  requestMethod: RequestMethodType,
  url: Url,
  successAction$: any
) => (action$: any, state$: any) =>
  action$.pipe(
    ofType(actionType),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      return requireValidToken(
        action$,
        state$,
        state.account.detail.tokens,
        newToken => {
          return ajax({
            method: requestMethod,
            url: `${BASE_URL}${url}`,
            body: qs.stringify({
              data: JSON.stringify({
                ...action.params,
              }),
            }),
            headers: {
              Authorization: `${newToken}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).pipe(
            successAction$,
            catchError(error => {
              return of(
                requestFailure(error.xhr.response.errors, error.status)
              );
            })
          );
        }
      );
    })
  );

export const getJSONEpic = (actionType: string, url: Url, actions$: any) => (
  action$: any,
  state$: any
) =>
  action$.pipe(
    ofType(actionType),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      return requireValidToken(
        action$,
        state$,
        state.account.detail.tokens,
        newToken => {
          const finalUrl = isEmpty(action.params)
            ? `${BASE_URL}${url}`
            : `${BASE_URL}${url}?${qs.stringify(action.params)}`;
          return ajax
            .getJSON(finalUrl, {
              Authorization: `${newToken}`,
            })
            .pipe(
              actions$,
              catchError(error => {
                return of(
                  requestFailure(error.xhr.response.errors, error.status)
                );
              })
            );
        }
      );
    })
  );
