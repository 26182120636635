import { URL_GET_COUNTRIES } from '../shared/urls';
// import { makeRequest } from '../redux/apiCall';
import countries from '../shared/const/countries';

export const GET_COUNTRIES_LIST = 'get_countries_list';
export const GET_COUNTRIES_LIST_SUCCESS = 'get_countries_list_success';
export const GET_COUNTRIES_LIST_FAILURE = 'get_countries_list_failure';

const fakeGettingCountries = ms =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      if (countries) {
        resolve(countries);
      } else {
        reject({ error: 'not found countries' });
      }
    }, ms);
  });

export const getCountries = () => async dispatch => {
  dispatch({ type: URL_GET_COUNTRIES });
  const res = await fakeGettingCountries(500);
  dispatch({ type: GET_COUNTRIES_LIST_SUCCESS, res });
};

// export const getCountries = () =>
//   makeRequest(GET_COUNTRIES_LIST, 'get', URL_GET_COUNTRIES, null, getCountries);
