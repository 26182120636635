import axios from 'axios';
import qs from 'qs';
import { isEmpty, omit, has } from 'lodash';
import { useBaseUrl, useToken, base64ToImage } from './helperHooks';

export function callApi(method, url, params, downloadFile) {
  const BASE_URL = useBaseUrl();
  const authToken = useToken().authToken;
  let fullUrl = `${BASE_URL}/${url}`;
  if (method === 'get') {
    fullUrl = isEmpty(params)
      ? `${BASE_URL}/${url}`
      : `${BASE_URL}/${url}?${qs.stringify(params)}`;
  }
  const paramsData = params && params.params ? params.params : params;
  const _dataSend = JSON.stringify({...!has(params, 'signature') ? paramsData : omit(paramsData, ['signature', 'headers'])});
  const _signatureSend = has(params, 'signature') && params.signature && base64ToImage(params.signature);
  let _data = qs.stringify({
    data: _dataSend,
    ..._signatureSend && { signature: params.signature },
  });
  let headers = {
    Authorization: `${authToken}`,
    ...has(params, 'headers') ? params.headers : {'content-type': 'application/x-www-form-urlencoded'},
  };
  
  if ( has(params, 'headers') && params.headers['Content-Type'] == 'multipart/form-data' ) {
    const formData = new FormData();
    formData.append('data', _dataSend);
    _signatureSend && formData.append('signature', _signatureSend);
    return axios[method](fullUrl, formData, {headers: headers});
  }

  const options = {
    method,
    headers: headers,
    data: _data,
    url: fullUrl,
    responseType: !!downloadFile ? 'blob' : null,
  };
  return axios(options);
}

export function uploadFile(url, data) {
  const BASE_URL = useBaseUrl();
  const authToken = useToken().authToken;
  let fullUrl = `${BASE_URL}/${url}`;
  const options = {
    method: 'post',
    headers: {
      Authorization: `${authToken}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
    url: fullUrl,
  };
  return axios(options);
}
