//@flow
import type { AccomdationList, Accomdation, Listing } from './model';
export const FETCH_ACCOMODATIONS = 'fetch_accomodations';
export const FETCH_ACCOMODATIONS_SUCCESS = 'fetch_accomodations_success';
export const FETCH_ACCOMODATIONS_FAILURE = 'fetch_accomodations_failure';
export const FETCH_ACCOMODATION_DETAIL = 'fetch_accomodation_detail';
export const RESET_ACCOMODATION_DETAIL = 'reset_accomodation_detail';
export const FETCH_ACCOMODATION_DETAIL_SUCCESS =
  'fetch_accomodation_detail_success';
export const FETCH_ACCOMODATION_DETAIL_FAILURE =
  'fetch_accomodation_detail_failure';

export const ADD_ACCOMODATION = 'add_accomodation';
export const ADD_ACCOMODATION_SUCCESS = 'add_accomodation_success';
export const ADD_ACCOMODATION_FAILURE = 'add_accomodation_failure';

export const ADD_NEW_LISTING = 'add_new_listing';
export const ADD_NEW_LISTING_SUCCESS = 'add_new_listing_success';
export const ADD_NEW_LISTING_FAILURE = 'add_new_listing_failure';

export const GET_LISTING_BY_ACCOMMODATION = 'get_listing_by_accommodation';
export const GET_LISTING_BY_ACCOMMODATION_SUCCESS =
  'get_listing_by_accommodation_success';
export const GET_LISTING_BY_ACCOMMODATION_FAILURE =
  'get_listing_by_accommodation_failure';

export const UPDATE_ACCOMMODATION = 'update_accommodation';
export const UPDATE_ACCOMMODATION_SUCCESS = 'update_accommodation_success';
export const UPDATE_ACCOMMODATION_FAILURE = 'update_accommodation_failure';

export type FetchAccomodations = {
  type: string,
};

export type FetchAccomodationsSuccess = {
  type: string,
  res: AccomdationList,
};

export type FetchAccomodationDetail = {
  type: string,
  id: number,
};

export type FetchAccomodationDetailSuccess = {
  type: string,
  detail: Accomdation,
};

export type AddAccomodation = {
  type: string,
  params: Accomdation,
};

export type AddAccomodationSuccess = {
  type: string,
  res: Accomdation,
};

export type ATAddNewListing = {
  type: string,
  params: Listing,
};

export type ATAddNewListingSuccess = {
  type: string,
  res: Listing,
};

export type ATGetListingByAccommodation = {
  type: string,
  params: Listing,
};

export type ATGetListingByAccommodationSuccess = {
  type: string,
  res: Array<Listing>,
};

export type ATUpdateAccommodation = {
  type: string,
  params: Accomdation,
};
export type ATUpdateAccommodationSuccess = {
  type: string,
  res: Accomdation,
};
