import { toast } from 'react-toastify';
import { REFRESH_TOKEN } from '../features/account/actionTypes';
import { callApi } from '../custom-hooks/apiHook';
import getErrorName from '../shared/const/errorCode';
import { removeItemFromStorage } from '../helpers/localStorage';
import { push } from 'connected-react-router'

function handleError(error, dispatch, resumeAction, params) {
  if (error.response) {
    if (error.response.status === 401) {
      if (error.response.data.errors[0].msg === 'NOT_AUTHORIZED') {
        dispatch({ type: REFRESH_TOKEN });
        setTimeout(() => {
          dispatch(resumeAction(params));
        }, 1000);
      } else {
        removeItemFromStorage('bestguest');
        // window.location.href = '/login';
        dispatch(push('/login'));
      }
    } else {
      if (error.response.data.errors[0]) {
        toast.error(`${getErrorName(error.response.data.errors[0].msg)}`);
      } else {
        toast.error('server have something wrong');
      }
    }
  } else if (error.request) {
    toast.error(`${error.request}`);
  } else {
    toast.error(`${error.message}`);
  }
}

export const makeRequest = (
  actionType,
  method,
  url,
  params,
  requestName,
  isUpdating,
  isFile = false,
) => async (dispatch, getState) => {
  dispatch({ type: actionType });
  try {
    const res = await callApi(method, url, params, isFile);
    dispatch({
      type: `${actionType}_success`,
      res: !!isUpdating ? params : (isFile ? res.data : res.data.data),
    });
  } catch (err) {
    handleError(err, dispatch, requestName, params);
  }
};
