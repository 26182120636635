import nprogress from 'nprogress';
import { includes } from 'lodash';

export default store => next => action => {
  if (
    !includes(action.type, 'modal') &&
    !includes(action.type, 'switch') &&
    !includes(action.type, 'drawer')
  ) {
    nprogress.start();
    nprogress.inc(0.2);
    next(action);
    nprogress.done(true);
  } else {
    next(action);
  }
};
