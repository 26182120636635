//@flow
import createReducer from '../../../redux/createReducer';
import produce from 'immer';
import {
  FETCH_ACCOMODATIONS,
  FETCH_ACCOMODATIONS_SUCCESS,
  FETCH_ACCOMODATIONS_FAILURE,
  ADD_ACCOMODATION_SUCCESS,
  ADD_ACCOMODATION,
} from '../actionTypes';
import type { AccomdationState } from '../model';
import type {
  FetchAccomodationsSuccess,
  AddAccomodationSuccess,
} from '../actionTypes';

const initalState: AccomdationState = {
  list: [],
  loading: false,
};

export default createReducer(initalState, {
  [FETCH_ACCOMODATIONS]: (state: AccomdationState) => ({
    ...state,
    loading: true,
  }),
  [FETCH_ACCOMODATIONS_SUCCESS]: (
    state: AccomdationState,
    action: FetchAccomodationsSuccess
  ) => ({
    ...state,
    loading: false,
    list: action.res,
  }),
  [ADD_ACCOMODATION]: (state: AccomdationState) => ({
    ...state,
    loading: true,
  }),
  [ADD_ACCOMODATION_SUCCESS]: (
    state: AccomdationState,
    action: AddAccomodationSuccess
  ) =>
    produce(state, draft => {
      draft.list.push(action.res);
      draft.loading = false;
    }),
  [FETCH_ACCOMODATIONS_FAILURE]: (state: AccomdationState) => ({
    ...state,
    loading: false,
  }),
});
