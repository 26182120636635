import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_PROPERTY_DETAIL,
  GET_PROPERTY_DETAIL_SUCCESS,
} from '../../actions/property';
import {
  UPDATE_LISTING_SUCCESS,
  CREATE_LISTING_SUCCESS,
  DELETE_LISTING_SUCCESS,
} from '../../actions/listing';

const initialState = {
  data: {
    accommodationId: 0,
    accommodationName: '',
    city: '',
    cityDistrict: '',
    country: '',
    district: '',
    haveImage: false,
    listings: [],
    listingsCount: 0,
    localFeeId: '',
    numberOfBeds: 0,
    orientationNumber: '',
    postalNumber: 0,
    registerNumber: '',
    rentingFrom: '',
    street: '',
    welcomerId: 0,
    welcomerName: '',
  },
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_PROPERTY_DETAIL]: state => {
    state.loading = true;
  },
  [GET_PROPERTY_DETAIL_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [UPDATE_LISTING_SUCCESS]: (state, action) => {
    state.data.listings = state.data.listings.map(lt => {
      if (lt.listingId === action.res.listingId) {
        return {
          ...lt,
          ...action.res,
        };
      } else {
        return lt;
      }
    });
  },
  [CREATE_LISTING_SUCCESS]: (state, action) => {
    state.data.listings.push(action.res);
  },
  [DELETE_LISTING_SUCCESS]: (state, action) => {
    state.data.listings = state.data.listings.filter(
      listing => listing.listingId !== action.res.listingId,
    );
  },
});
