//@flow
import type { LoginRequestModel, Token, UserResponseModel } from './model';

export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILURE = 'login_failure';

export const LOG_OUT = 'log_out';
export const LOG_OUT_SUCCESS = 'log_out_success';
export const LOG_OUT_FAILURE = 'log_out_failure';

export const SIGN_UP = 'sign_up';
export const SIGN_UP_SUCCESS = 'sign_up_success';
export const SIGN_UP_FAILURE = 'sign_up_failure';

export const UPDATE_PROFILE = 'update_profile';
export const UPDATE_PROFILE_SUCCESS = 'update_profile_success';

export const CONFIRM_EMAIL = 'confirm_email';
export const CONFIRM_EMAIL_SUCCESS = 'confirm_email_success';
export const CONFIRM_EMAIL_FAILURE = 'confirm_email_failure';

export const VERIFY_EMAIL = 'verify_email';
export const VERIFY_EMAIL_SUCCESS = 'verify_email_success';
export const VERIFY_EMAIL_FAILURE = 'verify_email_failure';

export const RESET_PASSWORD = 'reset_password';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILURE = 'reset_password_failure';

export const REFRESH_TOKEN = 'refresh_token';
export const REFRESH_TOKEN_SUCCESS = 'refresh_token_success';
export const REFRESH_TOKEN_FAILURE = 'refresh_token_failure';

export type LoginAction = {
  type: string,
  payload: LoginRequestModel,
};

export type LoginActionSuccess = {
  type: string,
  response: UserResponseModel,
};

export type LoginActionFailure = {
  type: string,
  error?: any,
};

export type LogoutAction = {
  type: string,
};

export type ATLogoutSuccess = {
  type: string,
  res: boolean,
};

export type SignUp = {
  type: string,
  params: LoginRequestModel,
};

export type SignUpSuccess = {
  type: string,
  response: UserResponseModel,
};

export type SignUpFailure = {
  type: string,
  error: any,
};

export type UpdateProfile = {
  type: string,
  params: UserResponseModel,
};

export type ConfirmMail = {
  type: string,
  params: any,
};

export type ResetPassword = {
  type: string,
  params: any,
};

export type ConfirmMailSuccess = {
  type: string,
  res: any,
};

export type ResetPasswordSuccess = {
  type: string,
  response: any,
};

export type ATRefreshToken = {
  type: string,
};

export type ATRefreshTokenSuccess = {
  type: string,
  res: Token,
};

export type VerifyEmail = {
  type: string,
  params: any,
};

export type VerifyEmailSuccess = {
  type: string,
  response: any,
};

