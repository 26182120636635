import { makeRequest } from '../redux/apiCall';
import {
  URL_UPDATE_LISTING,
  URL_LISTINGS_CREATE,
  URL_DELETE_LISTING,
} from '../shared/urls';

export const CREATE_LISTING = 'create_listing';
export const CREATE_LISTING_SUCCESS = 'create_listing_success';
export const CREATE_LISTING_FAILURE = 'create_listing_failure';

export const UPDATE_LISTING = 'update_listing';
export const UPDATE_LISTING_SUCCESS = 'update_listing_success';
export const UPDATE_LISTING_FAILURE = 'update_listing_failure';

export const DELETE_LISTING = 'delete_listing';
export const DELETE_LISTING_SUCCESS = 'delete_listing_success';
export const DELETE_LISTING_FAILURE = 'delete_listing_failure';

export const updateListing = params =>
  makeRequest(
    UPDATE_LISTING,
    'post',
    URL_UPDATE_LISTING,
    params,
    updateListing,
    true,
  );

export const createListing = params =>
  makeRequest(
    CREATE_LISTING,
    'post',
    URL_LISTINGS_CREATE,
    params,
    createListing,
  );

export const deleteListing = params =>
  makeRequest(
    DELETE_LISTING,
    'post',
    URL_DELETE_LISTING,
    params,
    deleteListing,
    true,
  );
