import createImmerReducer from '../redux/immer-reducer';
import {
  GET_LISTINGS_BY_PROPERTY_ID_SUCCESS,
  GET_LISTINGS_BY_PROPERTY_ID,
  GET_LISTINGS_BY_PROPERTY_ID_FAILURE,
} from '../actions/reservation';

const initialState = {
  listingsByPropertyId: {
    data: [],
    loading: false,
    error: '',
  },
};

export default createImmerReducer(initialState, {
  [GET_LISTINGS_BY_PROPERTY_ID]: state => {
    state.listingsByPropertyId.loading = true;
  },
  [GET_LISTINGS_BY_PROPERTY_ID_SUCCESS]: (state, action) => {
    state.listingsByPropertyId.loading = false;
    state.listingsByPropertyId.data = action.res;
  },
  [GET_LISTINGS_BY_PROPERTY_ID_FAILURE]: (state, action) => {
    state.listingsByPropertyId.loading = false;
    state.listingsByPropertyId.error = action.error;
  },
});
