// @flow
import type { ClientInfoModel, ResidentialClientModel } from './model';

export const GET_CLIENT_INFO = 'get_client_info';
export const GET_CLIENT_INFO_SUCCESS = 'get_client_info_success';

export const UPDATE_CLIENT_INFO = 'update_client_info';
export const UPDATE_CLIENT_INFO_SUCCESS = 'update_client_info_success';

export const CREATE_RESIDENTIAL_CLIENT = 'create_residential_client';
export const CREATE_RESIDENTIAL_CLIENT_SUCCESS =
  'create_residential_client_success';

export const UPDATE_RESIDENTIAL_CLIENT = 'update_residential_client';
export const UPDATE_RESIDENTIAL_CLIENT_SUCCESS =
  'update_residential_client_success';


export const GET_RESIDENTIAL_CLIENTS = 'get_residential_clients';
export const GET_RESIDENTIAL_CLIENTS_SUCCESS =
  'get_residential_clients_success';

export const DELETE_RESIDENTIAL_CLIENT = 'delete_residential_client';
export const DELETE_RESIDENTIAL_CLIENT_SUCCESS =
  'delete_residential_client_success';

export const SWITCH_KIND_OF_COMPANY = 'switch_kind_of_company';
export const OPEN_DRAWER = 'open_drawer';
export const CLOSE_DRAWER = 'close_drawer';

export type ATGetClientInfo = {
  type: string,
};

export type ATGetClientInfoSuccess = {
  type: string,
  res: ClientInfoModel,
};

export type ATCreateResidentialClient = {
  type: string,
  params: ResidentialClientModel,
};

export type ATCreateResidentialClientSuccess = {
  type: string,
  res: ResidentialClientModel,
};
