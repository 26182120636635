// @flow
import type {
  ClientInfoModel,
  ResidentialClientModel,
  ToggleParams,
} from './model';
import {
  CLOSE_DRAWER,
  CREATE_RESIDENTIAL_CLIENT,
  CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  UPDATE_RESIDENTIAL_CLIENT,
  DELETE_RESIDENTIAL_CLIENT,
  DELETE_RESIDENTIAL_CLIENT_SUCCESS,
  GET_CLIENT_INFO,
  GET_CLIENT_INFO_SUCCESS,
  GET_RESIDENTIAL_CLIENTS,
  GET_RESIDENTIAL_CLIENTS_SUCCESS,
  OPEN_DRAWER,
  SWITCH_KIND_OF_COMPANY,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO_SUCCESS,
} from './actionTypes';
import type {
  ATCreateResidentialClient,
  ATCreateResidentialClientSuccess,
  ATGetClientInfo,
  ATGetClientInfoSuccess,
} from './actionTypes';

export const getClientInfo = (): ATGetClientInfo => ({
  type: GET_CLIENT_INFO,
});

export const getClientInfoSuccess = (
  res: ClientInfoModel
): ATGetClientInfoSuccess => ({
  type: GET_CLIENT_INFO_SUCCESS,
  res,
});

export const createResidentialClient = (
  params: ResidentialClientModel
): ATCreateResidentialClient => ({
  type: CREATE_RESIDENTIAL_CLIENT,
  params,
});

export const updateResidentialClient = (
  params: ResidentialClientModel
): ATCreateResidentialClient => ({
  type: UPDATE_RESIDENTIAL_CLIENT,
  params,
});


export const createResidentialClientSuccess = (
  res: ResidentialClientModel
): ATCreateResidentialClientSuccess => ({
  type: CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  res,
});

export const getResidentialClients = () => ({
  type: GET_RESIDENTIAL_CLIENTS,
});

export const getResidentialClientsSuccess = (res: ResidentialClientModel) => ({
  type: GET_RESIDENTIAL_CLIENTS_SUCCESS,
  res,
});

export const switchKindOfCompany = (propertyManager: number) => ({
  type: SWITCH_KIND_OF_COMPANY,
  propertyManager,
});

export const updateClientInfo = (params: ClientInfoModel) => ({
  type: UPDATE_CLIENT_INFO,
  params,
});
export const updateClientInfoSuccess = (res: ClientInfoModel) => ({
  type: UPDATE_CLIENT_INFO_SUCCESS,
  res,
});

export const deleteResidentialClient = (params: ResidentialClientModel) => ({
  type: DELETE_RESIDENTIAL_CLIENT,
  params,
});

export const deleteResidentialClientSuccess = (res: any) => ({
  type: DELETE_RESIDENTIAL_CLIENT_SUCCESS,
  res,
});

export const openDrawer = (params: ToggleParams) => ({
  type: OPEN_DRAWER,
  params,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});
