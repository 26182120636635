//@flow

export const OPEN_MODAL = 'open_modal';
export const CLOSE_MODAL = 'close_modal';

export type OpenModal = {
  type: string,
  modalName: string,
};

export type CloseModal = {
  type: string,
  modalName: string,
};

export const openModal = (modalName: string): OpenModal => ({
  type: OPEN_MODAL,
  modalName,
});

export const closeModal = (modalName: string): CloseModal => ({
  type: CLOSE_MODAL,
  modalName,
});
