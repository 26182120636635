import { ofType } from 'redux-observable';
import {
  mergeMap,
  startWith,
  take,
  delay,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
} from '../features/account/actionTypes';
import moment from 'moment-timezone';

export function hasTokenExpired(tokens) {
  if (tokens && tokens.authToken && tokens.authExpire) {
    const current = moment.tz(new Date(), 'Asia/Ho_Chi_Minh');
    const expired = moment.tz(tokens.authExpire, 'Europe/Prague');
    const expiredToVn = expired.clone().tz('Asia/Ho_Chi_Minh');
    return expiredToVn.isBefore(current);
  }
  return false;
}

export const requireValidToken = (action$, state$, currentTokens, callback) => {
  const needsRefresh = hasTokenExpired(currentTokens);
  if (needsRefresh) {
    return action$.pipe(
      ofType(REFRESH_TOKEN_SUCCESS),
      delay(1000),
      take(1),
      takeUntil(action$.ofType(REFRESH_TOKEN_FAILURE)),
      withLatestFrom(state$),
      mergeMap(([action, state]) =>
        callback(state.account.detail.tokens.authToken)
      ),
      startWith({ type: REFRESH_TOKEN })
    );
  } else {
    return callback(currentTokens.authToken);
  }
};
