import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  SWITCH_COMPANY_TYPE
} from '../../actions/account';

const initialState = {
  data: {},
  loading: false,
  error: ''
};
export default createImmerReducer(initialState, {
  [GET_CLIENT]: state => {
    state.loading = true;
  },
  [SWITCH_COMPANY_TYPE]: (state, action) => {
    state.data.propertyManager = action.propertyManager;
  },
  [GET_CLIENT_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  }
});
