import produce from 'immer';
import createReducer from '../../../redux/createReducer';
import { GET_COUNTRIES, GET_COUNTRIES_SUCCESS } from '../actionTypes';
import type { ATGetCountriesSuccess } from '../actionTypes';
import type { CheckInFormState, CountryState } from '../model';

const initialState: CountryState = {
  countries: [],
  loading: false,
};

export default createReducer(initialState, {
  [GET_COUNTRIES]: (state: CountryState) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  [GET_COUNTRIES_SUCCESS]: (
    state: CountryState,
    action: ATGetCountriesSuccess
  ) =>
    produce((state: CheckInFormState), (draft: CountryState) => {
      draft.loading = false;
      draft.countries = action.res;
    }),
});
