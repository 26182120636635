// @flow
import React, { lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import DateFnsUtils from '@date-io/date-fns';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: blue,
    secondary: {
      light: '#0066ff',
      main: 'rgb(64, 191, 118)',
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
});

// const Home = AsyncLoader({ loader: () => import('../common/home') });

const Dashboard = lazy(() => import('../features/dashboard'));

const LoginForm = lazy(() => import('../features/account/containers/Login'));

const ForgotPassword = lazy(() =>
  import('../features/account/containers/ForgotPassword'),
);

const Register = lazy(() => import('../features/account/containers/Register'));

const VerifyEmail = lazy(() => import('../features/account/containers/VerifyEmail'));

const ResetPassword = lazy(() =>
  import('../features/account/containers/ResetPassword'),
);

const NotFound = () => <p>Sorry, nothing here</p>

const Routes = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Suspense fallback={<CircularProgress />}>
          <Router>
            <Dashboard path="/*" />
            <LoginForm path="login" />
            <ForgotPassword path="forgot-password" />
            <ResetPassword path="reset-password" />
            <Register path="register" />
            <VerifyEmail path="user/verifyEmail" />
            <NotFound default />
          </Router>
        </Suspense>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default Routes;
