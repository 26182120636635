import createReducer from '../../../redux/createReducer';
import produce from 'immer';
import {
  CREATE_CHECK_IN_FORM,
  CREATE_CHECK_IN_FORM_SUCCESS,
} from '../actionTypes';
import type { ATCheckInFormSuccess } from '../actionTypes';
import type { CheckInFormState } from '../model';

const initialState: CheckInFormState = {
  form: {
    reservationHash: '',
    firstName: '',
    lastName: '',
    birthDate: new Date(),
    state: '',
    city: '',
    district: '',
    street: '',
    houseNumber: '',
    checkIn: new Date(),
    checkOut: new Date(),
    visaNumber: 0,
  },
  loading: false,
};

export default createReducer(initialState, {
  [CREATE_CHECK_IN_FORM]: (state: CheckInFormState) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  [CREATE_CHECK_IN_FORM_SUCCESS]: (
    state: CheckInFormState,
    action: ATCheckInFormSuccess
  ) =>
    produce((state: CheckInFormState), (draft: CheckInFormState) => {
      draft.loading = false;
      draft.form = action.res;
    }),
});
