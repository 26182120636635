import createImmerReducer from '../../redux/immer-reducer';
import {
  FETCH_RESERVATION_DETAIL,
  FETCH_RESERVATION_DETAIL_SUCCESS,
  UPDATE_RESERVATION_SUCCESS,
  CREATE_GUEST_SUCCESS,
  CREATE_RESERVATION_TO_POLICE_SUCCESS,
} from '../../actions/reservation';
import { pick } from 'lodash';
import { ADD_NEW_LISTING_SUCCESS } from '../../features/accomodation/actionTypes';

const initialState = {
  data: {
    bookedBy: {},
    forms: [],
    listingId: 0,
    sentToPolice: false,
  },
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [FETCH_RESERVATION_DETAIL]: state => {
    state.loading = true;
  },
  [FETCH_RESERVATION_DETAIL_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [UPDATE_RESERVATION_SUCCESS]: (state, action) => {
    const bookedBy = pick(action.res, [
      'email',
      'phone',
      'firstName',
      'lastName',
    ]);
    state.data = { ...state.data, ...action.res };
    state.data.bookedBy = bookedBy;
  },
  [CREATE_GUEST_SUCCESS]: (state, action) => {
    state.data.forms.push(action.res);
  },
  [ADD_NEW_LISTING_SUCCESS]: (state, action) => {
    state.data.listingId += 1;
  },
  [CREATE_RESERVATION_TO_POLICE_SUCCESS]: (state, action) => {
    state.data.sentToPolice = true;
  },
});
