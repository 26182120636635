import createReducer from '../../../redux/createReducer';
import {
  GET_CLIENT_INFO,
  GET_CLIENT_INFO_SUCCESS,
  SWITCH_KIND_OF_COMPANY,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO_SUCCESS,
} from '../actionTypes';
import { produce } from 'immer';
import type { ClientInfoState } from '../model';
import type { ATGetClientInfoSuccess } from '../actionTypes';

const initialState: ClientInfoState = {
  data: {
    clientName: '',
    vatId: '',
    PropertyManager: 0,
    businessId: '',
    bankAccount: '',
    invoiceAddress: '',
    postalAddress: '',
    email: '',
    phoneNumber: '',
    contactName: '',
    ubyClientContact: '',
    ubyClientName: '',
    ubyClientNameShort: '',
    ubyUsername: '',
    ubyIDUB: '',
  },
  isUpdated: false,
  loading: false,
};

export default createReducer(initialState, {
  [GET_CLIENT_INFO]: (state: ClientInfoState) =>
    produce(state, draftState => {
      draftState.loading = true;
    }),
  [SWITCH_KIND_OF_COMPANY]: (state: ClientInfoState, action) =>
    produce(state, draftState => {
      draftState.data.propertyManager = action.propertyManager;
    }),
  [GET_CLIENT_INFO_SUCCESS]: (
    state: ClientInfoState,
    action: ATGetClientInfoSuccess
  ) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.data = action.res;
    }),
  [UPDATE_CLIENT_INFO]: (state: ClientInfoState) =>
    produce(state, draftState => {
      draftState.loading = true;
    }),
  [UPDATE_CLIENT_INFO_SUCCESS]: (state: ClientInfoState, action) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.isUpdated = action.res;
    }),
});
