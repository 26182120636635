import { combineReducers } from 'redux';
import reservationsList from './reservationsList';
import reservationDetail from './reservationDetail';
import checkinFormDetail from './checkinFormDetail';

export default combineReducers({
  reservationsList,
  detail: reservationDetail,
  checkinFormDetail: checkinFormDetail,
});
