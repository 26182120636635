// @flow

import type {
  ATExportGuestBook,
  ATExportGuestBookSuccess,
} from './actionTypes';
import { EXPORT_GUEST_BOOK, EXPORT_GUEST_BOOK_SUCCESS } from './actionTypes';
import type { ExportGuestBookParams } from './model';

export const exportGuestBookPDF = (
  params: ExportGuestBookParams
): ATExportGuestBook => ({
  type: EXPORT_GUEST_BOOK,
  params,
});

export const exportGuestBookPDFSuccess = (
  res: any
): ATExportGuestBookSuccess => ({
  type: EXPORT_GUEST_BOOK_SUCCESS,
  res,
});
