//@flow
import createReducer from '../redux/createReducer';
import { OPEN_MODAL, CLOSE_MODAL } from './actions';

export type ModalState = {
  open: boolean,
  modalName: string,
};
const initialState = {
  open: false,
  modalName: '',
};

export default createReducer(initialState, {
  [OPEN_MODAL]: (state: ModalState, action) => ({
    ...state,
    open: true,
    modalName: action.modalName,
  }),
  [CLOSE_MODAL]: (state: ModalState, action) => ({
    ...state,
    open: false,
    modalName: action.modalName,
  }),
});
