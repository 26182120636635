import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import 'nprogress/nprogress.css';
import App from './App';
import { Provider } from 'react-redux';
import { initStore, history } from './redux/configureStore';
import { getItemFromStorage } from './helpers/localStorage';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';

const existingUser = getItemFromStorage('bestguest');
let initialState;
if (existingUser) {
  initialState = {
    account: {
      detail: existingUser,
    },
  };
} else {
  initialState = {};
}

const store = initStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
