import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_RESIDENTIAL_CLIENT,
  GET_RESIDENTIAL_CLIENT_SUCCESS
} from '../../actions/account';

const initialState = {
  data: [],
  loading: false,
  error: ''
};
export default createImmerReducer(initialState, {
  [GET_RESIDENTIAL_CLIENT]: state => {
    state.loading = true;
  },
  [GET_RESIDENTIAL_CLIENT_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  }
});
