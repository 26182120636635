import createReducer from '../../../redux/createReducer';
import { produce } from 'immer';
import {
  ADD_NEW_LISTING,
  ADD_NEW_LISTING_SUCCESS,
  GET_LISTING_BY_ACCOMMODATION,
  GET_LISTING_BY_ACCOMMODATION_SUCCESS,
} from '../actionTypes';
import type { ListingListState } from '../model';
import type {
  ATAddNewListingSuccess,
  ATGetListingByAccommodationSuccess,
} from '../actionTypes';

const initialState: ListingListState = {
  listings: [],
  loading: false,
};

export default createReducer(initialState, {
  [ADD_NEW_LISTING]: (state: ListingListState) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  [ADD_NEW_LISTING_SUCCESS]: (
    state: ListingListState,
    action: ATAddNewListingSuccess
  ) =>
    produce(state, draft => {
      draft.loading = false;
      draft.listings.push(action.res);
    }),
  [GET_LISTING_BY_ACCOMMODATION]: (state: ListingListState) =>
    produce(state, draftState => {
      draftState.loading = true;
    }),

  [GET_LISTING_BY_ACCOMMODATION_SUCCESS]: (
    state: ListingListState,
    action: ATGetListingByAccommodationSuccess
  ) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.listings = action.res;
    }),
});
