import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, compose, applyMiddleware } from 'redux';
import createRootReducer, { rootEpic } from './root';
import crashReporter from '../middlewares/sentry-redux-middleware';
import { requiredAuthen } from '../middlewares/authentication';
import showProcessBar from '../middlewares/showProcessBar';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const initStore = initialState => {
  const store = createStore(
    createRootReducer(history),
    initialState || {},
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        epicMiddleware,
        requiredAuthen,
        crashReporter,
        showProcessBar,
      ),
    ),
  );
  epicMiddleware.run(rootEpic);
  return store;
};
