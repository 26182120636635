// @flow
import createReducer from '../../../redux/createReducer';
import {
  CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  DELETE_RESIDENTIAL_CLIENT_SUCCESS,
  GET_RESIDENTIAL_CLIENTS,
  GET_RESIDENTIAL_CLIENTS_SUCCESS,
} from '../actionTypes';
import { produce } from 'immer';
import type { ResidentialClientsState } from '../model';

const initialState: ResidentialClientsState = {
  data: [],
  loading: false,
};

export default createReducer(initialState, {
  [GET_RESIDENTIAL_CLIENTS]: (state: ResidentialClientsState) =>
    produce(state, draftState => {
      draftState.loading = true;
    }),
  [GET_RESIDENTIAL_CLIENTS_SUCCESS]: (state: ResidentialClientsState, action) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.data = action.res;
    }),
  [CREATE_RESIDENTIAL_CLIENT_SUCCESS]: (
    state: ResidentialClientsState,
    action
  ) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.data.push(action.res);
    }),
  [DELETE_RESIDENTIAL_CLIENT_SUCCESS]: (
    state: ResidentialClientsState,
    action
  ) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.data = state.data.filter(
        client => client.residentialClientId !== action.res.residentialClientId
      );
    }),
});
