import { combineReducers } from 'redux';
import residentialClientReducer from './residentialClientReducer';
import residentialClientsReducer from './residentialClientsReducer';
import clientInfoReducer from './clientInfoReducer';
import toggleDrawerReducer from './toggleDrawerReducer';

export default combineReducers({
  residentialClient: residentialClientReducer,
  residentialClientList: residentialClientsReducer,
  clientInfo: clientInfoReducer,
  drawer: toggleDrawerReducer,
});
