import { combineEpics } from 'redux-observable';
import { EXPORT_GUEST_BOOK } from './actionTypes';
import { EXPORT_GUEST_BOOK_URL } from '../../shared/urls';
import { map } from 'rxjs/operators';
import { exportGuestBookPDFSuccess } from './actions';
import requestEpic from '../../shared/requestEpic';
import { RequestMethod } from '../../shared/const/requestMethod';

const exportGuestBookEpic = requestEpic(
  EXPORT_GUEST_BOOK,
  RequestMethod.POST,
  EXPORT_GUEST_BOOK_URL,
  map(res => {
    return exportGuestBookPDFSuccess(res.response.data);
  })
);

export default combineEpics(exportGuestBookEpic);
