// @flow
import produce from 'immer';
import createReducer from '../../../redux/createReducer';
import { EXPORT_GUEST_BOOK, EXPORT_GUEST_BOOK_SUCCESS } from '../actionTypes';
import { THROW_ERROR } from '../../../redux/handleError';
import type { ATExportGuestBookSuccess } from '../actionTypes';
import type { ExportGuestBookState } from '../model';

const initialState: ExportGuestBookState = {
  isFetched: false,
  loading: false,
  error: '',
};
export default createReducer(initialState, {
  [EXPORT_GUEST_BOOK]: (state: ExportGuestBookState) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  [EXPORT_GUEST_BOOK_SUCCESS]: (
    state: ExportGuestBookState,
    action: ATExportGuestBookSuccess
  ) =>
    produce(state, draft => {
      draft.isFetched = !!action.res;
      draft.loading = false;
    }),
  [THROW_ERROR]: (state: ExportGuestBookState) =>
    produce(state, draft => {
      draft.loading = false;
    }),
});
