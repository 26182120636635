// @flow
import type {
  CheckInFormParams,
  CountriesModel,
  FetchReservationsParams,
  Reservation,
} from './model';
import type {
  ATCheckInForm,
  ATCheckInFormSuccess,
  ATGetCountries,
  ATGetCountriesSuccess,
  GetReservationDetail,
  GetReservationDetailSuccess,
  GetReservations,
  GetReservationsSuccess,
  ReservationActionsType,
} from './actionTypes';
import {
  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  GET_RESERVATION_DETAIL_SUCCESS,
  GET_RESERVATIONS,
  GET_RESERVATION_DETAIL,
  GET_RESERVATIONS_SUCCESS,
  CREATE_CHECK_IN_FORM,
  CREATE_CHECK_IN_FORM_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
} from './actionTypes';

export const createReservation = (
  params: Reservation,
): ReservationActionsType => ({
  type: CREATE_RESERVATION,
  params,
});

export const createReservationSuccess = (
  res: Reservation,
): ReservationActionsType => {
  return {
    type: CREATE_RESERVATION_SUCCESS,
    res,
  };
};

export const getReservations = (
  params: FetchReservationsParams,
): GetReservations => ({
  type: GET_RESERVATIONS,
  params,
});

export const getReservationsSuccess = (
  res: Array<Reservation>,
): GetReservationsSuccess => ({
  type: GET_RESERVATIONS_SUCCESS,
  res,
});

export const getReservationDetail = (id: number): GetReservationDetail => ({
  type: GET_RESERVATION_DETAIL,
  id,
});

export const getReservationDetailSuccess = (
  res: Reservation,
): GetReservationDetailSuccess => ({
  type: GET_RESERVATION_DETAIL_SUCCESS,
  res,
});

export const createCheckInForm = (
  formData: CheckInFormParams,
): ATCheckInForm => ({
  type: CREATE_CHECK_IN_FORM,
  params: formData,
});

export const createCheckInFormSuccess = (
  res: CheckInFormParams,
): ATCheckInFormSuccess => ({
  type: CREATE_CHECK_IN_FORM_SUCCESS,
  res,
});

export const getCountries = (): ATGetCountries => ({
  type: GET_COUNTRIES,
});

export const getCountriesSuccess = (
  res: CountriesModel,
): ATGetCountriesSuccess => ({
  type: GET_COUNTRIES_SUCCESS,
  res,
});
