import { THROW_ERROR } from '../redux/handleError';
import { removeItemFromStorage } from '../helpers/localStorage';
import { push } from 'connected-react-router';
import { result } from 'lodash';
import { toast } from 'react-toastify';
import getErrorName from '../shared/const/errorCode';

export const requiredAuthen = store => next => action => {
  if (action.type === THROW_ERROR && action.statusCode === 401) {
    if (action.errors && action.errors.length > 0 && action.errors[0].msg === 'MASTER_TOKEN_EXPIRED') {
      removeItemFromStorage('bestguest');
      // window.location.href = '/login';
      store.dispatch(push('/login'));
    }
    if(action.errors && typeof(action.errors) === 'object' && action.errors.errors && action.errors.errors.length > 0){
      const errorMsg = result(action.errors, 'errors[0].msg', 'Server error');
      toast.error(`Oop! Error: ${getErrorName(errorMsg)}`);
    }
    return next(action);
  }
  return next(action);
};
