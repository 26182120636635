import createImmerReducer from '../../redux/immer-reducer';
import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_SUCCESS,
  CREATED_RESERVATION_SUCCESS,
} from '../../actions/reservation';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [FETCH_RESERVATIONS]: state => {
    state.loading = true;
  },
  [FETCH_RESERVATIONS_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [CREATED_RESERVATION_SUCCESS]: (state, action) => {
    state.data.push(action.res);
  },
});
