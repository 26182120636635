// @flow
export const THROW_ERROR = 'THROW_ERROR';
type Error = {
  code: number,
  msg: string,
  note?: string,
};
type ActionFailureType = {
  type: string,
  errors: Array<Error>,
  statusCode: number,
  hasError: boolean,
};

export const requestFailure = (
  errors: any,
  statusCode: number
): ActionFailureType => {
  return {
    type: THROW_ERROR,
    errors,
    statusCode,
    hasError: true,
  };
};
