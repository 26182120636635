// @flow
import type {
  FetchAccomodations,
  FetchAccomodationsSuccess,
  FetchAccomodationDetail,
  FetchAccomodationDetailSuccess,
  AddAccomodation,
  AddAccomodationSuccess,
  ATUpdateAccommodation,
  ATUpdateAccommodationSuccess,
} from './actionTypes';
import {
  FETCH_ACCOMODATIONS,
  FETCH_ACCOMODATIONS_SUCCESS,
  FETCH_ACCOMODATION_DETAIL,
  FETCH_ACCOMODATION_DETAIL_SUCCESS,
  RESET_ACCOMODATION_DETAIL,
  ADD_ACCOMODATION,
  ADD_ACCOMODATION_SUCCESS,
  ADD_NEW_LISTING,
  ADD_NEW_LISTING_SUCCESS,
  GET_LISTING_BY_ACCOMMODATION,
  GET_LISTING_BY_ACCOMMODATION_SUCCESS,
  UPDATE_ACCOMMODATION,
  UPDATE_ACCOMMODATION_SUCCESS,
} from './actionTypes';

import type { Accomdation, AccomdationList, Listing } from './model';

export const getAccommodations = (): FetchAccomodations => {
  return {
    type: FETCH_ACCOMODATIONS,
  };
};

export const getAccommodationsSuccess = (
  res: AccomdationList
): FetchAccomodationsSuccess => {
  return {
    type: FETCH_ACCOMODATIONS_SUCCESS,
    res,
  };
};

export const getAccommodationDetail = (id: number): FetchAccomodationDetail => {
  return {
    type: FETCH_ACCOMODATION_DETAIL,
    id,
  };
};

export const getAccomodationDetailSuccess = (
  data: Accomdation
): FetchAccomodationDetailSuccess => {
  return {
    type: FETCH_ACCOMODATION_DETAIL_SUCCESS,
    detail: data,
  };
};

export const resetAccomodationDetail = () => {
  return {
    type: RESET_ACCOMODATION_DETAIL,
  };
};

export const addAccommodation = (params: Accomdation): AddAccomodation => {
  return {
    type: ADD_ACCOMODATION,
    params,
  };
};

export const addAccommodationSuccess = (
  res: Accomdation
): AddAccomodationSuccess => {
  return {
    type: ADD_ACCOMODATION_SUCCESS,
    res,
  };
};

export const addNewListing = (params: Listing) => ({
  type: ADD_NEW_LISTING,
  params,
});

export const addNewListingSuccess = (res: Listing) => ({
  type: ADD_NEW_LISTING_SUCCESS,
  res,
});

export const getListingByAccommodation = (accommodationId: number) => ({
  type: GET_LISTING_BY_ACCOMMODATION,
  params: { accommodationId },
});

export const getListingByAccommodationSuccess = (res: Array<Listing>) => ({
  type: GET_LISTING_BY_ACCOMMODATION_SUCCESS,
  res,
});

export const updateAccommodation = (
  params: Accomdation
): ATUpdateAccommodation => ({
  type: UPDATE_ACCOMMODATION,
  params,
});

export const updateAccommodationSuccess = (
  res: Accomdation
): ATUpdateAccommodationSuccess => ({
  type: UPDATE_ACCOMMODATION_SUCCESS,
  res,
});
