import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_PROPERTIES,
  GET_PROPERTIES_SUCCESS,
  ADD_PROPERTY_SUCCESS
} from '../../actions/property';

const initialState = {
  data: [],
  loading: false,
  error: ''
};
export default createImmerReducer(initialState, {
  [GET_PROPERTIES]: state => {
    state.loading = true;
  },
  [GET_PROPERTIES_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [ADD_PROPERTY_SUCCESS]: (state, action) => {
    state.data.push(action.res);
  }
});
