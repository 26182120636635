import {
  ProfileInfoUrl,
  URL_CLIENT_GET_INFO,
  URL_CLIENT_GET_RESIDENTIAL_CLIENTS,
} from '../shared/urls';
import { makeRequest } from '../redux/apiCall';

export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const RESET = 'reset';

export const GET_CLIENT = 'get_client';
export const GET_CLIENT_SUCCESS = 'get_client_success';
export const GET_RESIDENTIAL_CLIENT = 'get_residential_client';
export const GET_RESIDENTIAL_CLIENT_SUCCESS = 'get_residential_client_success';

export const SWITCH_COMPANY_TYPE = 'switch_company_type';

export const CHANGE_PASS_WORD = 'change_pass_word';
export const CHANGE_PASS_WORD_SUCCESS = 'change_pass_word_success';
export const CHANGE_PASS_WORD_FAILURE = 'change_pass_word_failure';

export const UPDATE_CLIENT_INFO = 'update_client_info';
export const UPDATE_CLIENT_INFO_SUCCESS = 'update_client_info_success';

export const login = params => ({ type: LOGIN, params });
export const logout = () => ({ type: LOGOUT });
export const reset = () => ({ type: RESET });

export const getClientInfo = () =>
  makeRequest(GET_CLIENT, 'get', URL_CLIENT_GET_INFO, null, getClientInfo);

export const getResidentialClient = () =>
  makeRequest(
    GET_RESIDENTIAL_CLIENT,
    'get',
    URL_CLIENT_GET_RESIDENTIAL_CLIENTS,
    null,
    getResidentialClient,
  );

export const switchCompanyType = propertyManager => ({
  type: SWITCH_COMPANY_TYPE,
  propertyManager,
});

export const changePassword = params => ({
  type: CHANGE_PASS_WORD,
  params,
});

export const updateClientInfo = params =>
  makeRequest(
    UPDATE_CLIENT_INFO,
    'post',
    ProfileInfoUrl.UPDATE_CLIENT_INFO_URL,
    params,
    updateClientInfo,
  );
