//@flow
import createReducer from '../../../redux/createReducer';
import { produce } from 'immer';
import {
  FETCH_ACCOMODATION_DETAIL,
  FETCH_ACCOMODATION_DETAIL_SUCCESS,
  FETCH_ACCOMODATION_DETAIL_FAILURE,
  RESET_ACCOMODATION_DETAIL,
  UPDATE_ACCOMMODATION,
  UPDATE_ACCOMMODATION_SUCCESS,
} from '../actionTypes';
import type { AccomdationDetailState, AccomdationState } from '../model';
import type {
  ATUpdateAccommodationSuccess,
  FetchAccomodationDetailSuccess,
} from '../actionTypes';

const initialState: AccomdationDetailState = {
  detail: {
    accommodationName: '',
    district: '',
    city: '',
    cityDistrict: '',
    street: '',
    registerNumber: '',
    orientationNumber: '',
    postalNumber: 0,
    accommodationId: 0,
    listingsCount: 0,
    numberOfBeds: 0,
    localFeeId: '',
    rentingFrom: '',
    listings: [],
  },
  updatedResult: false,
  loading: false,
};

export default createReducer(initialState, {
  [FETCH_ACCOMODATION_DETAIL]: (state: AccomdationState) => ({
    ...state,
    loading: true,
  }),
  [RESET_ACCOMODATION_DETAIL]: (state: AccomdationDetailState) => ({
    ...state,
    loading: false,
    detail: initialState.detail,
  }),
  [FETCH_ACCOMODATION_DETAIL_SUCCESS]: (
    state: AccomdationDetailState,
    action: FetchAccomodationDetailSuccess
  ) => ({
    ...state,
    loading: false,
    detail: action.detail,
  }),
  [FETCH_ACCOMODATION_DETAIL_FAILURE]: (state: AccomdationDetailState) => ({
    ...state,
    loading: false,
  }),
  [UPDATE_ACCOMMODATION]: (state: AccomdationDetailState) =>
    produce(state, draftState => {
      draftState.loading = true;
      draftState.updatedResult = false;
    }),
  [UPDATE_ACCOMMODATION_SUCCESS]: (
    state: AccomdationDetailState,
    action: ATUpdateAccommodationSuccess
  ) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.updatedResult = true;
    }),
});
