import { getItemFromStorage } from '../helpers/localStorage';
import { isEmpty } from 'lodash';

export function useBaseUrl() {
  return process.env.REACT_APP_BASE_URL;
}

export function useToken() {
  const existingUser = getItemFromStorage('bestguest');
  if (isEmpty(existingUser)) {
    window.location.href = '/login';
    return;
  }
  return existingUser.tokens;
}

export function base64ToImage(dataurl, fileName = 'singature.png') {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
}
